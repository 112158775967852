<template>
  <div v-if="visible == true">
    <CSidebar
      scrollable
      position="fixed"
      :unfoldable="sidebarUnfoldable"
      :visible="visible"
      :class="visible == true ? 'sidebar-rigth' : 'sidebar-rigth-hide hide'"
    >
      <div>
        <FormUser
          v-if="component == 'form-user'"
          @onChangeForm="onChangeFormUser"
        />

        <FormTag
          v-if="component == 'form-tag'"
          @onChangeFormTag="onChangeFormTag"
        />

        <FormTicketCheckList
          v-if="component == 'form-ticket-checklist'"
          @onChangeFormTicketCheckList="onChangeFormTicketCheckList"
          :item="item"
        />

        <FormTicketTask
          v-if="component == 'form-ticket-task'"
          @onChangeFormTicketTask="onChangeFormTicketTask"
          :item="item"
        />
        <FormAttendenceTask
          v-if="component == 'form-attendence-task'"
          @onChangeFormTicketTask="onChangeFormTicketTask"
          :item="item"
        />

        <FormTicketDate
          v-if="component == 'form-ticket-date'"
          @onChangeDateTicket="onChangeDateTicket"
          :item="item"
        />

        <FormProject
          v-if="component == 'form-project'"
          @onChangeFormProject="onChangeFormProject"
          :item="item"
        />
        <FormStatus
          v-if="component == 'form-status'"
          @onChangeFormStatus="onChangeFormStatus"
          :item="item"
        />
        <PFormImage
          v-if="component == 'p-form-image'"
          @onChangePFormImage="onChangePFormImage"
          :item="item"
        />
        <!-- Não será mais necessário importar o componente aqui
        pois o slot sabe qual template é para ser renderizado no side bar
        utilizando o  <template v-slot:actualItemSidebar> -->
        <slot name="actualItemSidebar" />
      </div>
    </CSidebar>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import { computed } from 'vue'
import FormUser from '@/views/user/Form.vue'
import FormTag from '@/views/tag/Form.vue'
import FormProject from '@/views/project/Form.vue'
import FormTicketCheckList from '@/views/ticket_checklist/Form.vue'
import FormTicketTask from '@/views/ticket_task/Form.vue'
import FormTicketDate from '@/views/kanban/components/DateTicket.vue'
import FormAttendenceTask from '@/views/suport/attendence_task/Form.vue'
import FormStatus from '@/views/status/Form.vue'
import PFormImage from '@/components/Protik/PFormImage.vue'
export default {
  props: { visible: Boolean, component: String, item: Object, width: String },
  name: 'AppSidebar',
  components: {
    FormUser,
    FormTag,
    FormTicketCheckList,
    FormTicketTask,
    FormTicketDate,
    FormProject,
    FormStatus,
    FormAttendenceTask,
    PFormImage,
  },
  setup() {
    const store = useStore()
    return {
      sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
    }
  },
  data() {
    return {
      width_model: this.width != null ? 40 : 15,
      modal_visible: this.visible,
    }
  },
  methods: {
    onChangeFormUser(item) {
      this.$emit('onChangeFormUser', item)
    },

    onChangeFormTag(item) {
      this.$emit('onChangeFormTag', item)
    },

    onChangeFormTicketCheckList(item) {
      this.$emit('onChangeFormTicketCheckList', item)
    },

    onChangeFormTicketTask(item) {
      this.$emit('onChangeFormTicketTask', item)
    },

    onChangeDateTicket(item) {
      this.$emit('onChangeDateTicket', item)
    },

    onChangeFormProject(item) {
      this.$emit('onChangeFormProject', item)
    },

    onChangeFormStatus(item) {
      this.$emit('onChangeFormStatus', item)
    },

    onChangeFormSuport(item) {
      this.$emit('onChangeFormSuport', item)
    },
    onChangePFormImage(item) {
      this.$emit('onChangePFormImage', item)
    },
  },
}
</script>
<style>
.sidebar-rigth {
  color: black;
  background-color: white;
  right: 0;
  min-width: 250px;
  width: v-bind(width_model + '%');
  left: unset !important;
  overflow-x: scroll;
}
.sidebar-rigth-hide {
  background-color: transparent;
}
/* .wrapper {
  overflow: auto;
  height: 100%;
} */
</style>
