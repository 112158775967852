<template>
  <b-card class="mt-2">
    <template #header>
      {{ form.id == null ? 'Cadastro' : 'Alteração: ' + form.id }}
      <b-badge variant="success" class="mr-2">Tag</b-badge>
    </template>
    <div>
      <b-form @submit="onSubmit" class="row">
        <b-form-group label="Descrição" class="col-md-12 col-sm-12 col-lg-12">
          <b-form-input v-model="form.description" required></b-form-input>
        </b-form-group>
        <b-row class="text-end">
          <b-col class="mb-3">
            <b-button class="ml-2" type="submit" variant="primary">
              Salvar
            </b-button>
            <b-button class="ml-2" variant="light" @click="onChangeFormTag()"
              >X</b-button
            >
          </b-col>
        </b-row>
      </b-form>
    </div>
  </b-card>
</template>

<script>
import service from '@/services/BaseService'
export default {
  props: {
    usuario: Object,
  },
  data() {
    return {
      form: this.usuario ? null : {},
    }
  },
  methods: {
    async onSubmit() {
      var http = new service(`tag`)
      if (this.form.id == null) {
        await http
          .create(this.form)
          .then((resp) => {
            this.$toast.success('Tag Criado com Sucesso!')
            this.$emit('onChangeFormTag', resp.data.data)
          })
          .catch((e) => {
            this.$toast.error(e.response.data.message)
          })
      } else {
        await http
          .update(this.form.id, this.form)
          .then(() => {
            this.$toast.success('Tag Atualizado com Sucesso!')
          })
          .catch((e) => {
            this.$toast.error(e.response.data.message)
          })
      }
    },
    onChangeFormTag() {
      this.$emit('onChangeFormTag')
    },
  },
}
</script>
