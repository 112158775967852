<template>
  <CHeader position="sticky" class="mb-4">
    <screen-short
      v-if="screenshotStatus"
      @destroy-component="destroyComponent"
      @get-image-data="getImg"
    >
    </screen-short>
    <CContainer fluid>
      <CHeaderToggler class="ps-1" @click="$store.commit('toggleSidebar')">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <CHeaderBrand class="mx-auto d-lg-none" to="/">
        <CIcon :icon="logo" height="48" alt="Logo" />
      </CHeaderBrand>
      <CHeaderNav class="d-none d-md-flex me-auto">
        <CNavItem>
          <CNavLink href="/dashboard"> Dashboard </CNavLink>
        </CNavItem>
      </CHeaderNav>
      <CHeaderNav>
        <CNavItem>
          <CNavLink
            ><span>sessão expira em: {{ timerCount }} minuto(s)</span></CNavLink
          >
        </CNavItem>
        <CNavItem>
          <CNavLink href="#">
            <CIcon class="mx-2" icon="cil-bell" size="lg" />
          </CNavLink>
        </CNavItem>
        <CNavItem>
          <CNavLink>
            <CIcon class="mx-2" icon="cil-settings" size="lg" @click="print" />
          </CNavLink>
        </CNavItem>
        <AppHeaderDropdownAccnt />
      </CHeaderNav>
    </CContainer>
    <CHeaderDivider />
    <CContainer fluid>
      <AppBreadcrumb />
      <AppHeaderDefault />
    </CContainer>
  </CHeader>
</template>

<script>
import { useStore } from 'vuex'
import { computed } from 'vue'
import moment from 'moment'
import VueJwtDecode from 'vue-jwt-decode'
import AppBreadcrumb from './AppBreadcrumb.vue'
import AppHeaderDefault from './AppHeaderDefault.vue'
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt.vue'
import Auth from '../layouts/Auth.vue'
import { logo } from '@/assets/brand/logo'
import { ref } from 'vue'

export default {
  name: 'AppHeader',
  components: {
    AppBreadcrumb,
    AppHeaderDefault,
    AppHeaderDropdownAccnt,
  },
  data() {
    return {
      timerEnabled: true,
      timerCount: 0,
    }
  },
  setup() {
    const store = useStore()
    const screenshotStatus = ref(false)
    const destroyComponent = function (status) {
      screenshotStatus.value = status
    }

    return {
      user: computed(() => store.state.user),
      logo,
      screenshotStatus,
      destroyComponent,
    }
  },
  methods: {
    print() {
      this.screenshotStatus = !this.screenshotStatus
    },
  },
  watch: {
    timerEnabled(value) {
      if (value) {
        setTimeout(() => {
          this.timerCount--
        }, 1000)
      }
    },

    timerCount: {
      handler() {
        const { exp } = VueJwtDecode.decode(localStorage.token)
        var tempo = exp - (new Date().getTime() + 1) / 1000
        var duration = moment.duration(tempo, 'seconds')
        setTimeout(() => {
          this.timerCount = duration.minutes() + ':' + duration.seconds()
        }, 1000)
        if (exp < (new Date().getTime() + 1) / 1000) {
          this.$toast.warning('Sua sessão expirou!')
          this.$router.push({
            name: 'Login',
          })
        } else if (exp - (new Date().getTime() + 1) / 1000 <= 59) {
          var _user = {
            login: this.user.login_user,
            password: this.user.password,
          }
          Auth.methods.refreshToken(_user)
        }
      },
      immediate: true,
    },
  },
}
</script>
