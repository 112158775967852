<template>
  <b-overlay :show="show_overlay" rounded="sm" class="tab">
    <template #overlay>
      <div class="text-center">
        <ProgressSpinner />
        <p id="cancel-label">Salvando imagem...</p>
      </div>
    </template>
    <b-card class="mt-2">
      <template #header>
        Cadastro
        <b-badge variant="success" class="mr-2">Imagens</b-badge>
      </template>
      <div>
        <div v-if="tipo_documento">
          Tipo Documento:
          <b-badge variant="success">{{ this.tipo_documento }}</b-badge>
        </div>
        <b-form @submit="onSubmit" class="row" :key="key">
          <!-- <b-form-group label="Tabela" class="col-lg-6">
          <b-form-input v-model="form.tabela" required disabled></b-form-input>
        </b-form-group>
        <b-form-group label="N° Autorização" class="col-lg-6">
          <b-form-input
            v-model="form.tabela_id"
            required
            disabled
          ></b-form-input>
        </b-form-group> -->
          <b-form-group label="Imagem" class="col-lg-12">
            <input
              type="file"
              ref="files"
              required
              accept="image/jpg,image/png,image/jpeg,.pdf"
              @change="onChangeImagem"
            />
          </b-form-group>
          <b-row class="text-end">
            <b-col class="mb-3">
              <b-button class="ml-2" type="submit" variant="primary">
                Salvar
              </b-button>
              <!-- <b-button class="ml-2" variant="light" @click="onChangePFormImage()"
              >X</b-button
            > -->
            </b-col>
          </b-row>
        </b-form>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import service from '@/services/BaseService'
export default {
  props: { item: Object },
  data() {
    return {
      form: {},
      key: 0,
      imagem: null,
      tipo_documento: null,
      show_overlay: false,
    }
  },
  created() {
    this.form.tabela = this.item.tabela || 'Ticket'
    this.form.tabela_id = this.item.tabela_id || this.item.id
  },
  methods: {
    async onSubmit() {
      try {
        this.show_overlay = true
        let data = new FormData()
        let file = this.imagem.target.files[0]
        if (file.size > 2000000) {
          this.show_overlay = false
          this.$emit('onChangePFormImage', 'Erro ao salvar imagem')
          this.$toast.error('Tamanho da imagem maior que 2MB.')
          return
        }

        if (file.type == 'application/pdf') {
          data.append('type', 'pdf')
        }
        if (file.type == 'image/jpeg') {
          data.append('type', 'jpg')
        }
        if (file.type == 'image/jpg') {
          data.append('type', 'jpg')
        }
        if (file.type == 'image/png') {
          data.append('type', 'png')
        }
        data.append('tabela', this.form.tabela)
        data.append('tabela_id', this.form.tabela_id)
        data.append('imagem', file)
        var http = new service(`image`)
        await http
          .createFormData(data)
          .then((resp) => {
            this.$toast.success('Imagem Salva com Sucesso!')
            this.$emit('onChangePFormImage', resp.data.data)
            this.show_overlay = false
          })
          .catch((e) => {
            this.show_overlay = false
            this.$toast.error(e)
          })
      } catch (error) {
        this.show_overlay = false
        this.$toast.error(error.message)
      }
    },
    onChangeImagem(event) {
      if (event) {
        this.imagem = event
        let file = this.imagem.target.files[0]
        if (
          file.type == 'application/pdf' ||
          file.type == 'image/jpg' ||
          file.type == 'image/jpeg' ||
          file.type == 'image/png'
        ) {
          if (file.type == 'application/pdf') {
            this.tipo_documento = 'pdf'
          } else {
            this.tipo_documento = 'image/jpg'
          }
        } else {
          this.imagem = null
          this.key++
          alert('Formato de imagem invalido!!')
        }
      } else {
        this.imagem = null
      }
    },
    onChangePFormImage() {
      this.$emit('onChangePFormImage')
    },
  },
}
</script>
