import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import vueKanban from 'vue-kanban'

import mitt from 'mitt'
const emitter = mitt()

import BootstrapVue3 from 'bootstrap-vue-3'
import BootstrapIcon from '@dvuckovic/vue3-bootstrap-icons'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import axios from 'axios'
import VueAxios from 'vue-axios'

import VueToast from 'vue-toast-notification'
// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-sugar.css'

import AppSidebarRight from '@/components/AppSidebarRight.vue'

import Datepicker from 'vue3-date-time-picker'
import 'vue3-date-time-picker/dist/main.css'

import VCalendar from 'v-calendar'
import 'v-calendar/dist/style.css'
//import { vueKeycloak } from '@baloise/vue-keycloak'
import ganttastic from '@infectoone/vue-ganttastic'
import VueHtmlToPaper from '../plugins/VueHtmlToPaper'
import screenShort from 'vue-web-screen-shot'
import jsClipScreen from 'js-clip-screen'
import Vue3ColorPicker from 'vue3-colorpicker'
import 'vue3-colorpicker/style.css'

import PStatusAttendence from '@/components/Protik/PStatusAttendence.vue'
import VueSimpleAlert from 'vue3-simple-alert-next'

//Prime VUe
import PrimeVue from 'primevue/config'
import DataTable from 'primevue/datatable'
import Card from 'primevue/card'
import Column from 'primevue/column'
import ColumnGroup from 'primevue/columngroup'
import Row from 'primevue/row'
import Button from 'primevue/button'
import Badge from 'primevue/badge'
import SelectButton from 'primevue/selectbutton'
import Dialog from 'primevue/dialog'
import InputMask from 'primevue/inputmask'
import Sidebar from 'primevue/sidebar'
import InputSwitch from 'primevue/inputswitch'
import InputText from 'primevue/inputtext'
import PickList from 'primevue/picklist'
import OverlayPanel from 'primevue/overlaypanel'
import Calendar from 'primevue/calendar'
import Tooltip from 'primevue/tooltip'
import 'primevue/resources/themes/saga-blue/theme.css' //theme
import 'primevue/resources/primevue.min.css' //core css
import 'primeicons/primeicons.css' //icons

import ConfirmDialog from 'primevue/confirmdialog'
import ConfirmationService from 'primevue/confirmationservice'

import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

// COMPONENTES PROTIK
//Cadastro de Imagens
import PProjectCombo from '@/components/Protik/PProjectCombo.vue'
import PPersonCombo from '@/components/Protik/PPersonCombo.vue'
import PUserCombo from '@/components/Protik/PUserCombo.vue'
import PFormImage from '@/components/Protik/PFormImage.vue'
import Multiselect from '@vueform/multiselect'
import PPageNotPermission from '@/views/NotPermission.vue'

import VueApexCharts from 'vue3-apexcharts'

import { Money3Component } from 'v-money3'

const app = createApp(App)

app.config.globalProperties.emitter = emitter

app.use(store)
app.use(router)
app.use(ganttastic)
app.use(CoreuiVue)
app.provide('icons', icons)
app.directive('tooltip', Tooltip)
app.use(vueKanban)
app.use(BootstrapVue3)
app.use(VueAxios, axios)
app.use(VueToast, { position: 'top' })
app.use(VCalendar, {})
app.mount('#app')
app.use(VueHtmlToPaper)
app.use(screenShort, {
  enableWebRtc: true,
  level: 10000000000000,
  clickCutFullScreen: true,
})
app.use(jsClipScreen)
app.use(Vue3ColorPicker)
app.use(VueSimpleAlert)
app.use(PrimeVue, {
  locale: {
    startsWith: 'Começa com',
    contains: 'Contém',
    notContains: 'Não contém',
    endsWith: 'Termina com',
    equals: 'É igual a',
    notEquals: 'Não é igual',
    noFilter: 'Sem Filtro',
    lt: 'Menor que',
    lte: 'Menor que ou igual a',
    gt: 'Maior que',
    gte: 'Maior que ou igual a',
    dateIs: 'A data é',
    dateIsNot: 'A data não é',
    dateBefore: 'A data é anterior',
    dateAfter: 'A data é depois',
    clear: 'Limpar',
    apply: 'Aplicar',
    matchAll: 'Corresponder a Todos',
    matchAny: 'Corresponder a Qualquer',
    addRule: 'Adicionar regra',
    removeRule: 'Remover regra',
    accept: 'Sim',
    reject: 'Não',
    choose: 'Escolher',
    upload: 'Envio',
    cancel: 'Cancelar',
    dayNames: [
      'Domingo',
      'Segunda',
      'Terça',
      'Quarta',
      'Quinta',
      'Sexta',
      'Sábado',
    ],
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
    dayNamesMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
    monthNames: [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ],
    monthNamesShort: [
      'Jan',
      'Fev',
      'Mar',
      'Abr',
      'Mai',
      'Jun',
      'Jul',
      'Ago',
      'Set',
      'Out',
      'Nov',
      'Dez',
    ],
    today: 'Hoje',
    weekHeader: 'Sem',
    firstDayOfWeek: 0,
    dateFormat: 'mm/dd/yy',
    weak: 'Fraco',
    medium: 'Medio',
    strong: 'Forte',
    passwordPrompt: 'Insira uma Senha',
    emptyFilterMessage: 'Nenhum resultado encontrado',
    emptyMessage: 'Nenhuma opção disponível',
  },
})

app.component('CIcon', CIcon)
app.component('BootstrapIcon', BootstrapIcon)
app.component('AppSidebarRight', AppSidebarRight)
app.component('Datepicker', Datepicker)
app.component('PProjectCombo', PProjectCombo)
app.component('PPersonCombo', PPersonCombo)
app.component('PUserCombo', PUserCombo)
app.component('PStatusAttendence', PStatusAttendence)
app.component('PFormImagem', PFormImage)
app.component('Multiselect', Multiselect)
app.component('PPageNotPermission', PPageNotPermission)
app.component('PMoney', Money3Component)
app.component('DataTable', DataTable)
app.component('Column', Column)
app.component('ColumnGroup', ColumnGroup)
app.component('Row', Row)
app.component('PrimeButton', Button)
app.component('Badge', Badge)
app.component('SelectButton', SelectButton)
app.component('Dialog', Dialog)
app.component('InputMask', InputMask)
app.component('Sidebar', Sidebar)
app.component('InputSwitch', InputSwitch)
app.component('InputText', InputText)
app.component('PickList', PickList)
app.component('OverlayPanel', OverlayPanel)
app.component('PrimeCalendar', Calendar)
app.component('apexchart', VueApexCharts)
app.component('VueDatePicker', VueDatePicker)
app.component('ConfirmDialog', ConfirmDialog)
app.component('Card', Card)
app.use(ConfirmationService)
export default app
