<template>
  <b-form-group label="Projeto">
    <b-form-select
      class="form-control"
      v-model="mutableProp.project_id"
      :key="key"
      @change="onChangeCombo"
    >
      <b-form-select-option
        v-for="obj in array_projetos"
        :key="obj"
        :value="obj.id"
        >{{ obj.description }}</b-form-select-option
      >
    </b-form-select>
  </b-form-group>
</template>

<script>
import service from '@/services/BaseService'
export default {
  props: ['form', 'callback', 'project_attendance'],
  data() {
    return {
      key: 0,
      mutableProp: this.form,
      call: this.callback,
      array_projetos: [],
    }
  },
  mounted() {
    this.getProjetos()
  },

  watch: {
    form(value) {
      this.mutableProp.project_id = value.project_id

      this.key++
    },
  },

  methods: {
    onChangeCombo(value) {
      this.$emit('onChangeCombo', value)
    },
    onChange(value) {
      this.mutableProp.project_id = value
      // eslint-disable-next-line vue/no-mutating-props
      this.form = this.mutableProp
      this.key++
    },
    async getProjetos() {
      var url = `project`
      if (this.project_attendance) {
        url = `project_attendance`
      }
      var http = new service(url)
      await http
        .find()
        .then((resp) => {
          this.array_projetos = resp.data.data
          this.key++
        })
        .catch(() => {})
    },
  },
}
</script>
