<template>
  <div class="text-center mb-5 mt-5">
    <img src="../assets/not-permission.png" height="100" />
    <div class="h5 mt-2">
      <span>Sem permissão de acesso!</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ['error'],
}
</script>
