<template>
  <b-card class="mt-2">
    <template #header>
      {{ form.id == null ? 'Cadastro' : 'Alteração: ' + form.id }}
      <b-badge variant="success" class="mr-2">Tarefa</b-badge>
    </template>

    <b-form @submit="onSubmit" inline>
      <div class="row">
        <div class="col-md-12">
          <b-form-group label="Usuário">
            <b-form-select
              class="form-control"
              v-model="form.user_person_id"
              :options="array_users"
              value-field="id"
              text-field="login_user"
              required
            ></b-form-select>
          </b-form-group>
        </div>
        <!-- <div class="col-md-12">
          <b-form-group label="CheckList">
            <b-form-select
              class="form-control"
              v-model="form.checklist_id"
              :options="array_checklist"
              value-field="id"
              text-field="description"
              required
            ></b-form-select>
          </b-form-group>
        </div> -->
      </div>
      <b-form-group label="Descrição">
        <b-form-textarea
          v-model="form.description"
          rows="5"
          max-rows="9"
        ></b-form-textarea>
      </b-form-group>
      <!-- <b-form-group label="Hora Inicio">
        <v-date-picker mode="time" v-model="form.hour_start" is24hr />
      </b-form-group>
      <b-form-group label="Hora Fim">
        <v-date-picker mode="time" v-model="form.hour_end" is24hr />
      </b-form-group> -->
      <b-row class="text-end">
        <b-col class="mb-3">
          <b-button
            v-if="form.id != null"
            class="ml-2"
            variant="success"
            @click="finalizar()"
          >
            Finalizar
          </b-button>
          <b-button class="ml-2" type="submit" variant="primary">
            Salvar
          </b-button>
          <b-button
            class="ml-2"
            variant="light"
            @click="onChangeFormTicketTask()"
            >X</b-button
          >
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import service from '@/services/BaseService'
import moment from 'moment'
import { useStore } from 'vuex'
import { computed } from 'vue'
export default {
  components: {},
  props: {
    item: Object,
  },
  setup() {
    const store = useStore()
    return {
      user: computed(() => store.state.user),
    }
  },

  data() {
    return {
      form: this.item,
      array_users: [],
      array_checklist: this.item.checkLists,
    }
  },
  created() {
    //this.setTimePicker()
    this.getUsuarios()
    this.form.user_person_id = this.user.id
  },
  methods: {
    async finalizar() {
      if (this.form.description == null || this.form.description == '') {
        this.$toast.warning('A Descrição não pode ser vazia!')
        return
      }

      if (this.form.hour_end < this.form.hour_start) {
        this.$toast.warning(
          'Horário fim não pode ser maior que horário inicio!',
        )
        this.form.hour_end = null
      } else {
        let oldDate = this.form.date
        delete this.form.checkLists
        delete this.form.date

        var http = new service(`ticket_task`)

        let formMut = Object.assign({}, this.form)
        formMut.hour_end = this.msToHMS(new Date().getTime())

        await http
          .update(this.form.id, formMut)
          .then(() => {
            this.$toast.success('Tarefa Atualizado com Sucesso!')
            // this.form.hour_start = old_hour_start
            this.form.hour_end = formMut.hour_end
            this.form.date = oldDate
            this.$emit('onChangeFormTicketTask')
          })
          .catch((e) => {
            this.$toast.error(e.response.data.message)
          })
      }
    },
    async onSubmit() {
      let oldDate = this.form.date
      delete this.form.checkLists
      delete this.form.date
      var old_hour_start = this.form.hour_start
      var old_hour_end = this.form.hour_end

      var http = new service(`ticket_task`)
      // this.form.date = moment(new Date()).format('YYYY-MM-DD')
      if (this.form.id == null) {
        await http
          .create(this.form)
          .then((resp) => {
            this.$toast.success('Task Criado com Sucesso!')
            this.form.hour_start = old_hour_start
            this.form.hour_end = old_hour_end
            this.$emit('onChangeFormTicketTask', resp.data.data)
          })
          .catch((e) => {
            this.$toast.error(e.response.data.message)
          })
      } else {
        await http
          .update(this.form.id, this.form)
          .then(() => {
            this.$toast.success('Tarefa Atualizado com Sucesso!')
            this.form.hour_start = old_hour_start
            this.form.hour_end = old_hour_end
            this.form.date = oldDate
            this.$emit('onChangeFormTicketTask')
          })
          .catch((e) => {
            this.$toast.error(e.response.data.message)
          })
      }
    },
    msToHMS(duration) {
      // eslint-disable-next-line no-unused-vars
      var milliseconds = parseInt((duration % 1000) / 100),
        // seconds = parseInt((duration / 1000) % 60),
        minutes = parseInt((duration / (1000 * 60)) % 60),
        hours = parseInt((duration / (1000 * 60 * 60)) % 24)
      hours = hours - 3
      hours = hours < 10 ? '0' + hours : hours
      minutes = minutes < 10 ? '0' + minutes : minutes
      // seconds = seconds < 10 ? '0' + seconds : seconds

      return hours + ':' + minutes
    },
    async getUsuarios() {
      var http = new service(`user`)
      await http
        .find()
        .then((resp) => {
          this.array_users = resp.data.data
        })
        .catch(() => {})
    },

    onChangeFormTicketTask() {
      this.$emit('onChangeFormTicketTask')
    },

    setTimePicker() {
      var today = new Date()
      if (this.form.hour_start == null) {
        this.form.hour_start = new Date()
      } else {
        this.form.hour_start = moment(this.form.hour_start, 'H:mm:ss').format(
          'H:mm:ss',
        )
        var horastart = this.form.hour_start.split(':')[0]
        var minutostart = this.form.hour_start.split(':')[1]
        this.form.hour_start = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          horastart,
          minutostart,
          0,
        )
      }

      if (this.form.hour_end == null) {
        this.form.hour_end = new Date()
      } else {
        this.form.hour_end = moment(this.form.hour_end, 'H:mm:ss').format(
          'H:mm:ss',
        )
        var horaend = this.form.hour_end.split(':')[0]
        var minutoend = this.form.hour_end.split(':')[1]
        this.form.hour_end = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate(),
          horaend,
          minutoend,
          0,
        )
      }
    },
  },
}
</script>
