<template>
  <div v-for="item in breadcrumbs" :key="item">
    <div
      v-if="
        item.name == 'Dashboard' ||
        item.name == 'Usuário' ||
        item.name == 'Kanban' ||
        item.name == 'Projetos' ||
        item.name == 'Gantt' ||
        item.name == 'Agenda' ||
        item.name == 'Status' ||
        item.name == 'Sprint' ||
        item.name == 'Daily' ||
        item.name == 'Suporte' ||
        item.name == 'Prospeccao' ||
        item.name == 'Funcionalidade' ||
        item.name == 'Ticket' ||
        item.name == 'Graficos' ||
        item.name == 'Ticket List' ||
        item.name == 'Diário' ||
        item.name == 'Relatório de Horas'
      "
    >
      <b-button
        class="mr-2"
        variant="light"
        @click="openActions()"
        v-if="item.hasActions"
      >
        <BootstrapIcon icon="tools" color="black" />
        Ações
      </b-button>
      <b-button variant="light" @click="openFilter()">
        <BootstrapIcon icon="filter" color="black" />
        Filtro
      </b-button>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import router from '@/router'

export default {
  emits: ['openFilter'],
  data() {
    return {
      action_crm: false,
      form: null,
      item: null,
    }
  },
  created() {
    //Escuta as ações disparadas pela tela de modulos
    //habilita as opções de ações
    this.emitter.on('modal-actions', (value) => {
      if (value) {
        this.action_crm = true
        this.form = value.form
        this.item = value.item
      } else {
        this.action_crm = false
        this.form = null
        this.item = null
      }
    })
  },
  setup() {
    const breadcrumbs = ref()
    const getBreadcrumbs = () => {
      return router.currentRoute.value.matched.map((route) => {
        return {
          active: route.path === router.currentRoute.value.fullPath,
          name: route.name,
          hasActions: route.props.default.hasActions,
          path: `${router.options.history.base}${route.path}`,
        }
      })
    }
    router.afterEach(() => {
      breadcrumbs.value = getBreadcrumbs()
    })
    onMounted(() => {
      breadcrumbs.value = getBreadcrumbs()
    })

    return {
      breadcrumbs,
    }
  },
  methods: {
    openFilter() {
      this.emitter.emit('modal-filter')
    },
    openActions() {
      this.emitter.emit('modal-actions')
    },

    onActionCRM(action) {
      this.emitter.emit('action-crm', {
        action: action,
        form: this.form,
        item: this.item,
      })
    },
    onActionCalendar() {
      this.emitter.emit('action-crm-calendar')
    },
  },
}
</script>
