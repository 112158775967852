<template>
  <div class="container-fluid">
    <div class="row no-gutter">
      <div class="col-md-6 d-none d-md-flex bg-image"></div>
      <div class="col-md-6 bg-light">
        <div class="container-fluid">
          <div class="row no-gutter justify-content-center mt-5">
            <div class="col-md-12 bg-light">
              <div class="login d-flex align-items-center py-5">
                <div class="container">
                  <div class="row">
                    <div class="col-lg-10 col-xl-7 mx-auto">
                      <h3 class="display-4">Protik</h3>
                      <p class="text-muted mb-4">
                        Plataforma Controle de Tickets
                      </p>
                      <b-form @submit="submit">
                        <div class="mb-3">
                          <input
                            v-model="form.login"
                            placeholder="Login"
                            required
                            class="form-control rounded-pill border-0 shadow-sm px-4"
                          />
                        </div>
                        <div class="mb-3">
                          <input
                            v-model="form.password"
                            type="password"
                            placeholder="Senha"
                            required
                            class="form-control rounded-pill border-0 shadow-sm px-4 text-primary"
                          />
                        </div>
                        <div class="d-grid gap-2 mt-2">
                          <b-button
                            variant="primary"
                            type="submit"
                            class="text-uppercase mb-2 rounded-pill"
                          >
                            Login
                          </b-button>
                        </div>
                      </b-form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import service from '@/services/BaseService'
import { useStore } from 'vuex'
import { computed } from 'vue'
import { startOfMonth, endOfMonth } from 'date-fns'
export default {
  setup() {
    const store = useStore()
    return {
      user: computed(() => store.state.user),
    }
  },
  data() {
    return {
      form: {
        login: null,
        password: null,
      },
      loading: false,
    }
  },
  methods: {
    async submit() {
      try {
        var http = new service('authLogin')
        await http.create(this.form).then(async (resp) => {
          if (resp.data.success == true) {
            this.$store.commit({
              type: 'setUser',
              value: resp.data.data,
            })
            this.$store.commit({
              type: 'setFilterTicketlist',
              value: {
                users: [resp.data.data.id].join(','),
                dates: [
                  moment(startOfMonth(new Date())).format('YYYY-MM-DD HH:mm'),
                  moment(endOfMonth(new Date())).format('YYYY-MM-DD HH:mm'),
                ],
              },
            })
            localStorage.setItem('token', resp.data.token)
            var httpDireitos = new service(
              `user_project/search/params?user_id=${resp.data.data.id}`,
            )
            await httpDireitos.find().then((resp) => {
              this.$store.commit({
                type: 'setUserProjectsAssociation',
                value: resp.data.data,
              })
            })
            this.$toast.success('Logado com Sucesso!')
            this.$router.push({
              name: 'Dashboard',
            })
          } else {
            this.$toast.error(resp.data.message)
          }
        })
      } catch (e) {
        if (e.response.status != 401) {
          this.$toast.error(e.response.data.message)
        }
      }
    },
    async refreshToken(user) {
      try {
        var http = new service('authLogin')
        await http.create(user).then((resp) => {
          if (resp.data.success == true) {
            localStorage.setItem('token', resp.data.token)
          } else {
            this.$router.push({
              name: 'Login',
            })
          }
        })
      } catch (e) {
        this.$router.push({
          name: 'Login',
        })
      }
    },
  },
}
</script>
<style scoped>
.login,
.image {
  min-height: 100vh;
}
.bg-image {
  background-image: url('../../src/assets/login-split.jpeg');
  background-size: cover;
  background-position: center center;
}
</style>
