<template>
  <b-card class="mt-2">
    <template #header>
      Ticket:{{ form.id }}
      <b-badge variant="success" class="mr-2">Datas Ticket</b-badge>
    </template>
    <div>
      <b-form @submit="onSubmit" class="row">
        <b-form-group label="Ínicio Planejamento" class="col-lg-6">
          <v-date-picker
            v-model="form.plan_start"
            mode="dateTime"
            is24hr
            :timezone="timezone"
          />
        </b-form-group>
        <b-form-group label="Fim Planejamento" class="col-lg-6">
          <v-date-picker
            v-model="form.plan_end"
            mode="dateTime"
            is24hr
            :timezone="timezone"
          />
        </b-form-group>
        <b-form-group label="Ínicio Realizado" class="col-lg-6">
          <b-badge variant="primary" style="font-size: 14px">{{
            form.actual_start ? form.actual_start : ' - '
          }}</b-badge>
        </b-form-group>
        <b-form-group label="Fim Realizado" class="col-lg-6">
          <b-badge variant="primary" style="font-size: 14px">{{
            form.actual_end ? form.actual_end : ' - '
          }}</b-badge>
        </b-form-group>
        <b-row class="text-end">
          <b-col class="mb-3">
            <b-button
              class="ml-2"
              type="submit"
              variant="primary"
              v-if="this.item.id"
            >
              Salvar
            </b-button>
            <b-button class="ml-2" variant="light" @click="onChangeDateTicket()"
              >X</b-button
            >
          </b-col>
        </b-row>
      </b-form>
    </div>
  </b-card>
</template>

<script>
import service from '@/services/BaseService'
import moment from 'moment-timezone'
moment.tz.setDefault('America/Sao_Paulo')

export default {
  props: {
    item: Object,
  },
  data() {
    return {
      form: this.item,
      timezone: 'America/Sao_Paulo',
    }
  },
  created() {
    if (this.form.actual_start) {
      this.form.actual_start = moment(
        this.form.actual_start,
        'YYYY-MM-DD H:mm',
      ).format('DD-MM-YYYY H:mm')
    }
    if (this.form.actual_end) {
      this.form.actual_end = moment(
        this.form.actual_end,
        'YYYY-MM-DD H:mm',
      ).format('DD-MM-YYYY H:mm')
    }
    if (this.form.plan_start) {
      this.form.plan_start = moment(
        this.form.plan_start,
        'YYYY-MM-DD H:mm',
      ).format('YYYY-MM-DD H:mm')
    }
    if (this.form.plan_end) {
      this.form.plan_end = moment(this.form.plan_end, 'YYYY-MM-DD H:mm').format(
        'YYYY-MM-DD H:mm',
      )
    }
  },
  methods: {
    async onSubmit() {
      var body = {
        plan_start: moment(this.form.plan_start).format(),
        plan_end: moment(this.form.plan_end).format(),
      }
      console.log(body)
      var http = new service(`ticket`)
      await http
        .update(this.form.id, body)
        .then(() => {
          this.$toast.success('Datas Atualizados com Sucesso!')
          this.$emit('onChangeDateTicket')
        })
        .catch(({ response }) => {
          this.$toast.error(response.data.message)
        })
    },
    onChangeDateTicket() {
      this.$emit('onChangeDateTicket')
    },
  },
}
</script>
