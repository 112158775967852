<template>
  <b-card class="mt-2">
    <template #header>
      <b-badge variant="success" class="mr-2">Usuário</b-badge>
    </template>
    <div>
      <b-form @submit="onSubmit" class="row">
        <b-form-group label="id" class="col-lg-1">
          <b-form-input v-model="form.id" disabled></b-form-input>
        </b-form-group>
        <b-form-group label="Login" class="col-lg-4">
          <b-form-input v-model="form.login_user" required></b-form-input>
        </b-form-group>
        <b-form-group label="Senha" class="col-lg-4">
          <b-form-input
            type="password"
            v-model="form.password"
            required
          ></b-form-input>
        </b-form-group>
        <b-row class="text-end">
          <b-col class="mb-3">
            <b-button class="ml-2" type="submit" variant="primary">
              Salvar
            </b-button>
            <b-button class="ml-2" variant="light" @click="onChangeForm()"
              >X</b-button
            >
          </b-col>
        </b-row>
      </b-form>
    </div>
  </b-card>
</template>

<script>
import service from '@/services/BaseService'
import { useStore } from 'vuex'
import { computed } from 'vue'
export default {
  props: {
    item: Object,
  },
  setup() {
    const store = useStore()
    return {
      user: computed(() => store.state.user),
    }
  },
  data() {
    return {
      form: this.item,
    }
  },
  methods: {
    async onSubmit() {
      var http = new service(`user`)

      await http.update(this.form.id, this.form).then(() => {
        this.$toast.success('Usuário Atualizado com Sucesso!')
        var user = this.user
        user.password = this.form.password
        user.login_user = this.form.login_user
        this.$store.commit({
          type: 'setUser',
          value: user,
        })
      })
    },
    onChangeForm() {
      this.$emit('onChangeForm')
    },
  },
}
</script>
