import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'
import AuthLayout from '@/layouts/Auth.vue'
import DefaultLayout from '@/layouts/DefaultLayout.vue'

const routes = [
  {
    path: '/login',
    redirect: 'login',
    component: AuthLayout,
    children: [
      {
        path: '/login',
        name: 'Login',
        component: AuthLayout,
        meta: {
          title: 'LOGIN',
        },
      },
    ],
  },
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('@/views/dashboard/Dashboard.vue'),
      },
      {
        path: '/projetos',
        name: 'Projetos',
        component: () => import('@/views/project/List.vue'),
      },
      {
        path: '/status',
        name: 'Status',
        component: () => import('@/views/status/List.vue'),
      },
      {
        path: '/sprint',
        name: 'Sprint',
        component: () => import('@/views/sprint/List.vue'),
      },
      {
        path: '/notPermission',
        name: 'Sem Permissão de Acesso',
        component: () => import('@/views/NotPermission.vue'),
      },
    ],
  },
  {
    path: '/projetos',
    name: 'Projeto',
    component: DefaultLayout,
    redirect: '/projetos',
    children: [
      {
        path: '/schedule',
        name: 'Agenda',
        props: {},
        component: () => import('@/views/schedule/List.vue'),
      },
      {
        path: '/gantt',
        name: 'Gantt',
        props: { hasActions: true },
        component: () => import('@/views/gantt/List.vue'),
      },
      {
        path: '/kanban',
        name: 'Kanban',
        component: () => import('@/views/kanban/Kanban.vue'),
      },
      {
        path: '/daily',
        name: 'Daily',
        component: () => import('@/views/daily/List.vue'),
      },
    ],
  },
  {
    path: '/graficos',
    name: 'Graficos',
    component: DefaultLayout,
    redirect: '/graficos',
    children: [
      {
        path: '/charts',
        name: 'Gráficos dos Projetos',
        props: {},
        component: () => import('@/views/charts/List.vue'),
      },
    ],
  },
  {
    path: '/ticket_detail',
    name: 'Ticket detail',
    component: DefaultLayout,
    redirect: '/ticket_detail',
    children: [
      {
        path: '/ticket_detail',
        name: 'Ticket',
        component: () => import('@/views/gantt/components/FormModal.vue'),
      },
    ],
  },
  {
    path: '/tickets',
    name: 'Ticket',
    component: DefaultLayout,
    redirect: '/list-tickets',
    children: [
      {
        path: '/list-tickets',
        name: 'ListaTickets',
        component: () => import('@/views/ticket/List.vue'),
      },
    ],
  },
  {
    path: '/tickets-list',
    name: 'Ticket List',
    component: DefaultLayout,
    redirect: '/tickets-list',
    children: [
      {
        path: '/tickets-list',
        name: 'Lista Tickets',
        component: () => import('@/views/ticket_crud/List.vue'),
      },
    ],
  },
  {
    path: '/suporte',
    name: 'Suporte',
    component: DefaultLayout,
    redirect: '/list-projects-suport',
    children: [
      {
        path: '/suporte',
        name: 'Tickets Suporte',
        component: () => import('@/views/suport/List.vue'),
      },
      {
        path: '/suporte/:id',
        name: 'ListaAtendimento',
        component: () => import('@/views/suport/List.vue'),
      },
      {
        path: '/suporte_dashboard',
        name: 'Suporte Dashboard',
        component: () => import('@/views/suport/List.vue'),
      },
      {
        path: '/list-projects-suport',
        name: 'lista-projetos',
        component: () => import('@/views/suport/ListProjects.vue'),
      },
      {
        path: '/attendence/:id',
        name: 'Atendimento',
        component: () => import('@/views/suport/attendence/Attendence.vue'),
      },
    ],
  },
  {
    path: '/card_modules',
    name: 'CRM',
    component: DefaultLayout,
    redirect: '/card_modules',
    children: [
      {
        path: '/classe',
        name: 'Classe',
        component: () => import('@/views/crm_class/List.vue'),
      },
      {
        path: '/contato_setor',
        name: 'Contato Setor',
        component: () => import('@/views/crm_contact_sector/List.vue'),
      },
      {
        path: '/contato',
        name: 'Contato',
        component: () => import('@/views/crm_contact/List.vue'),
      },
      {
        path: '/prospeccao',
        name: 'Prospeccao',
        component: () => import('@/views/crm_prospection/List.vue'),
      },
      {
        path: '/opportunity_status',
        name: 'Oportunidade Status',
        component: () => import('@/views/crm_opportunity_status/List.vue'),
      },
      {
        path: '/opportunity',
        name: 'Oportunidade',
        component: () => import('@/views/crm_opportunity/List.vue'),
      },
      {
        path: '/type_follow_up',
        name: 'Tipo Acompanhamento',
        component: () => import('@/views/crm_type_follow_up/List.vue'),
      },
      {
        path: '/follow_up',
        name: 'Acompanhamento',
        component: () => import('@/views/crm_follow_up/List.vue'),
      },
      {
        path: '/schedule_follow_up',
        name: 'Agenda de Acompanhamentos',
        props: {},
        component: () => import('@/views/schedule_follow_up/List.vue'),
      },
      {
        path: '/card_modules',
        name: 'Empresas',
        component: () => import('@/views/crm_dashboard/card_modules.vue'),
      },
      {
        path: '/card_opportunity',
        name: 'Oportunidades / Card',
        component: () => import('@/views/crm_dashboard/card_opportunity.vue'),
      },
      {
        path: '/card_prospection',
        name: 'Prospections / Card',
        component: () => import('@/views/crm_dashboard/card_prospection.vue'),
      },
      {
        path: '/card_sheduled_day',
        name: 'Pessoas / Card',
        component: () => import('@/views/crm_dashboard/card_sheduled_day.vue'),
      },
      {
        path: '/card_contact',
        name: 'Contatos / Card',
        component: () => import('@/views/crm_dashboard/card_contact.vue'),
      },
    ],
  },
  {
    path: '/utils',
    name: 'Utils',
    component: DefaultLayout,
    redirect: '/utils',
    children: [
      {
        path: '/user',
        name: 'Usuário',
        component: () => import('@/views/user/List.vue'),
      },
      {
        path: '/profile',
        name: 'Perfil',
        component: () => import('@/views/profile/List.vue'),
      },
      {
        path: '/functionality',
        name: 'Funcionalidade',
        component: () => import('@/views/functionality/List.vue'),
      },
      {
        path: '/right',
        name: 'Direito',
        component: () => import('@/views/right/List.vue'),
      },
    ],
  },
  {
    path: '/hours_report',
    name: 'Resumos',
    component: DefaultLayout,
    redirect: '/hours_report',
    children: [
      {
        path: '/hours_report',
        name: 'Relatório de Horas',
        component: () => import('@/views/hours_report/List.vue'),
      },
    ],
  },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '404',
        name: 'Page404',
        component: () => import('@/views/pages/Page404.vue'),
      },
      {
        path: '500',
        name: 'Page500',
        component: () => import('@/views/pages/Page500.vue'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/register', '/home']
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = localStorage.getItem('token')
  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login')
  } else {
    next()
  }
})

export default router
