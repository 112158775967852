<template>
  <b-form @submit="onSubmit" inline>
    <b-card class="mt-2">
      <template #header>
        <b-row class="text-end">
          <b-col style="text-align: start">
            <b-badge variant="success">TAREFA</b-badge>
            {{ form.id == null ? 'Cadastro' : 'Alteração: ' + form.id }}
          </b-col>
          <b-col>
            <b-button class="ml-2" type="submit" variant="primary">
              Salvar
            </b-button>
            <b-button
              variant="light"
              class="ml-2"
              @click="onChangeFormTicketCheckList()"
              >X</b-button
            >
          </b-col>
        </b-row>
      </template>

      <b-form-group :label="'Prioridade: ' + form.priority">
        <div class="row text-start">
          <div class="col-md-12 col-sm-12 col-lg-12">
            <b-form-input
              v-model="form.priority"
              type="range"
              min="1"
              max="9"
            ></b-form-input>
          </div>
        </div>
      </b-form-group>
      <b-form-group label="Usuário">
        <b-form-select
          class="mb-2 mr-sm-2 mb-sm-0 col-md-12 form-control"
          v-model="form.user_person_id"
          :options="array_users"
          value-field="id"
          text-field="login_user"
          required
        ></b-form-select>
      </b-form-group>
      <b-form-group label="Descrição">
        <b-form-textarea
          v-model="form.description"
          required
          rows="10"
        ></b-form-textarea>
      </b-form-group>

      <b-card style="min-height: 100%">
        <template #header>
          <b-row>
            <b-col>
              <h5>
                <BootstrapIcon icon="list-task" color="black" />
                Execução
              </h5>
            </b-col>
          </b-row>
        </template>
        <b-table
          v-if="form.id"
          small
          :items="array_tasks"
          :fields="[
            { key: 'date', label: 'Data', class: 'text-center' },
            { key: 'hour_start', label: 'Inicio', class: 'text-center' },
            { key: 'hour_end', label: 'Fim', class: 'text-center' },
            { key: 'Ações', label: '', class: 'text-center' },
          ]"
          responsive="sm"
        >
          <!--    <template #cell(Ações)="data">
            <b-button-group>
              <b-button
                class="ml-2"
                pill
                variant="light"
                @click="deleteTask(data.item)"
              >
                <BootstrapIcon icon="trash" color="red" />
              </b-button>
            </b-button-group>
          </template> -->
        </b-table>
      </b-card>
    </b-card>
  </b-form>
</template>

<script>
import service from '@/services/BaseService'
import moment from 'moment'
export default {
  props: {
    item: { ticket_id: String, description: String },
  },
  data() {
    return {
      form: this.item,
      array_users: [],
      array_tasks: [],
    }
  },
  created() {
    console.log(this.form.ticket_id)
    this.getUsuarios()
    this.getTasks()
  },

  methods: {
    async onSubmit() {
      delete this.form.user
      var i = this.array_users
        .map((item) => item.id)
        .indexOf(this.form.user_person_id)
      var http = new service(`ticket_checklist`)
      if (this.form.id == null) {
        await http
          .create(this.form)
          .then((resp) => {
            resp.data.data.user = this.array_users[i].login_user
            this.$emit('onChangeFormTicketCheckList', resp.data.data)
          })
          .catch((e) => {
            this.$toast.error(e.response.data.message)
          })
      } else {
        delete this.form.created_at
        delete this.form.totalMinutos
        delete this.form.habilita_start
        delete this.form.habilita_stop
        await http
          .update(this.form.id, this.form)
          .then(() => {
            this.form.user = this.array_users[i].login_user
            this.$emit('onChangeFormTicketCheckList')
          })
          .catch((e) => {
            this.$toast.error(e.response.data.message)
          })
      }
    },
    async getUsuarios() {
      var http = new service(`user`)
      await http
        .find()
        .then((resp) => {
          this.array_users = resp.data.data
        })
        .catch(() => {})
    },
    async getTasks() {
      var http = new service(
        `ticket_task/search/params?ticket_id=${this.form.ticket_id}&checklist_id=${this.form.id}`,
      )
      await http
        .find()
        .then((resp) => {
          this.array_tasks = resp.data.data
          this.array_tasks.forEach((item) => {
            if (item.hour_start) {
              item.hour_start = moment(item.hour_start, 'H:mm:ss').format(
                'H:mm',
              )
            }
            if (item.hour_end) {
              item.hour_end = moment(item.hour_end, 'H:mm:ss').format('H:mm')
            }
            if (item.date) {
              item.date = moment(item.date, 'YYYY-MM-DD H:mm:ss').format(
                'DD-MM-YYYY',
              )
            }
          })
        })
        .catch(() => {})
    },

    async deleteTask(item) {
      var http = new service(`ticket_task`)
      await http.delete(item.id).then(() => {
        let i = this.array_tasks.map((item) => item.id).indexOf(item.id)
        this.array_tasks.splice(i, 1)
      })
    },

    onChangeFormTicketCheckList() {
      this.$emit('onChangeFormTicketCheckList')
    },
  },
}
</script>
