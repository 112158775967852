<template>
  <b-card class="mt-2">
    <template #header>
      {{ form.id == null ? 'Cadastro' : 'Alteração: ' + form.id }}
      <b-badge variant="success" class="mr-2">Tarefa</b-badge>
    </template>

    <b-form @submit="onSubmit" inline>
      <div class="row">
        <div class="col-md-12">
          <b-form-group label="Usuário">
            <b-form-select
              class="form-control"
              v-model="form.user_person_id"
              :options="array_users"
              value-field="id"
              text-field="login_user"
              required
            ></b-form-select>
          </b-form-group>
        </div>
        <div class="col-md-12">
          <b-form-group label="Tarefa">
            <b-form-select
              class="form-control"
              v-model="form.checklist_id"
              :options="array_checklist"
              value-field="id"
              text-field="description"
              required
            ></b-form-select>
          </b-form-group>
        </div>
      </div>
      <b-form-group label="Hora Início">
        <InputMask v-model="form.hour_start" mask="99:99" slotChar="hh:mm" />
      </b-form-group>
      <b-form-group label="Hora Fim">
        <InputMask v-model="form.hour_end" mask="99:99" slotChar="hh:mm" />
      </b-form-group>
      <b-row class="text-end">
        <b-col class="mb-3">
          <b-button class="ml-2" type="submit" variant="primary">
            Salvar
          </b-button>
          <b-button
            class="ml-2"
            variant="light"
            @click="onChangeFormTicketTask()"
            >X</b-button
          >
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import service from '@/services/BaseService'

export default {
  components: {},
  props: {
    item: Object,
  },
  data() {
    return {
      form: this.item,
      array_users: [],
      array_checklist: this.item.checkLists,
    }
  },
  created() {
    this.getUsuarios()
  },
  methods: {
    async onSubmit() {
      delete this.form.checkLists
      if (this.form.hour_start == '') {
        this.form.hour_start = null
      }
      if (this.form.hour_end == '') {
        this.form.hour_end = null
      }
      if (this.form.hour_start == null && this.form.hour_end != '') {
        this.form.hour_start = this.form.hour_end
      }
      if (this.form.hour_start != null && this.form.hour_end != null) {
        var _hora_start = parseInt(this.form.hour_start.replaceAll(':', ''))
        var _hour_end = parseInt(this.form.hour_end.replaceAll(':', ''))
        if (_hora_start > _hour_end) {
          this.form.hour_start = this.form.hour_end
        }
      }
      var http = new service(`ticket_task`)
      if (this.form.id == null) {
        await http
          .create(this.form)
          .then((resp) => {
            this.$toast.success('Tarefa Criado com Sucesso!')
            this.$emit('onChangeFormTicketTask', resp.data.data)
          })
          .catch((e) => {
            this.$toast.error(e.response.data.message)
          })
      } else {
        await http
          .update(this.form.id, this.form)
          .then(() => {
            this.$toast.success('Tarefa Atualizado com Sucesso!')
            this.$emit('onChangeFormTicketTask')
          })
          .catch((e) => {
            this.$toast.error(e.response.data.message)
          })
      }
    },

    async getUsuarios() {
      var http = new service(`user`)
      await http
        .find()
        .then((resp) => {
          this.array_users = resp.data.data
        })
        .catch(() => {})
    },

    onChangeFormTicketTask() {
      this.$emit('onChangeFormTicketTask')
    },
  },
}
</script>
