<template>
  <CBreadcrumb class="d-md-down-none me-auto mb-0">
    <CBreadcrumbItem
      v-for="item in breadcrumbs"
      :key="item"
      :href="item.active ? '' : item.path"
      :active="item.active"
    >
      <a v-if="item.name != 'Kanban'">{{ item.name }}</a>
    </CBreadcrumbItem>
    <div v-for="item in breadcrumbs" :key="item">
      <div
        v-if="
          item.name == 'Kanban' ||
          item.name == 'Daily' ||
          item.name == 'Gantt' ||
          item.name == 'Agenda'
        "
      >
        <div class="ml-2">
          <b-badge variant="primary" style="font-size: 14px">{{
            project.description
          }}</b-badge>
        </div>
      </div>
      <div v-else></div>
    </div>
  </CBreadcrumb>
</template>

<script>
import { onMounted, ref } from 'vue'
import router from '@/router'
import { useStore } from 'vuex'
import { computed } from 'vue'

export default {
  name: 'AppBreadcrumb',
  setup() {
    const breadcrumbs = ref()
    const store = useStore()

    const getBreadcrumbs = () => {
      return router.currentRoute.value.matched.map((route) => {
        return {
          active: route.path === router.currentRoute.value.fullPath,
          name: route.name,
          path: `${router.options.history.base}${route.path}`,
        }
      })
    }

    router.afterEach(() => {
      breadcrumbs.value = getBreadcrumbs()
    })

    onMounted(() => {
      breadcrumbs.value = getBreadcrumbs()
    })

    return {
      breadcrumbs,
      project: computed(() => store.state.project),
    }
  },
}
</script>
