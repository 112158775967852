<template>
  <b-card class="mt-2">
    <template #header>
      {{ form.id == null ? 'Cadastro' : 'Alteração: ' + form.id }}
      <b-badge variant="success" class="mr-2">Status</b-badge>
    </template>
    <div>
      <b-form @submit="onSubmit" class="row">
        <b-form-group label="Tipo Status" class="col-lg-4">
          <b-form-select v-model="form.status_type_id" class="form-control">
            <b-form-select-option :value="null"></b-form-select-option>
            <b-form-select-option
              v-for="obj in array_status"
              :key="obj"
              :value="obj.id"
              >{{ obj.description }}</b-form-select-option
            >
          </b-form-select>
        </b-form-group>
        <b-form-group label="Descrição" class="col-lg-8">
          <b-form-input v-model="form.description" required></b-form-input>
        </b-form-group>
        <b-form-group label="slaDefault" class="col-lg-2">
          <b-form-input type="number" v-model="form.slaDefault"></b-form-input>
        </b-form-group>
        <b-form-group label="Fluxo de Trabalho Final" class="col-lg-2">
          <b-form-checkbox switch v-model="form.endworkflow" color="success">
          </b-form-checkbox>
        </b-form-group>
        <b-form-group label="Cor" class="col-lg-auto">
          <!-- <b-form-input v-model="form.color"></b-form-input> -->
          <color-picker
            disabled
            disableHistory
            roundHistory
            disableAlpha
            isWidget
            format="hex"
            v-model:pureColor="form.color"
            v-model:gradientColor="gradientColor"
          />
        </b-form-group>
        <b-row class="text-end">
          <b-col class="mb-3">
            <b-button class="ml-2" type="submit" variant="primary">
              Salvar
            </b-button>
            <b-button class="ml-2" variant="light" @click="onChangeFormStatus()"
              >X</b-button
            >
          </b-col>
        </b-row>
      </b-form>
    </div>
  </b-card>
</template>

<script>
const table = 'status'
import { ref } from 'vue'
import service from '@/services/BaseService'
import { ColorInputWithoutInstance } from 'tinycolor2'
export default {
  props: {
    item: Object,
  },
  setup() {
    const pureColor = ref < ColorInputWithoutInstance > 'red'
    const gradientColor = ref(
      'linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 1) 100%)',
    )
    return { pureColor, gradientColor }
  },
  data() {
    return {
      form: this.item,
      array_status: [],
    }
  },
  created() {
    this.getTipoStatus()
  },
  methods: {
    async getTipoStatus() {
      var http = new service(`status_type`)
      await http
        .find()
        .then((resp) => {
          this.array_status = resp.data.data
        })
        .catch(() => {})
    },

    async onSubmit() {
      var http = new service(table)
      if (this.form.id == null) {
        await http
          .create(this.form)
          .then((resp) => {
            this.$toast.success('Status Criado com Sucesso!')
            this.$emit('onChangeFormStatus', resp.data.data)
          })
          .catch((e) => {
            this.$toast.error(e.response.data.message)
          })
      } else {
        await http
          .update(this.form.id, this.form)
          .then(() => {
            this.$toast.success('Status Atualizado com Sucesso!')
            this.$emit('onChangeFormStatus')
          })
          .catch((e) => {
            this.$toast.error(e.response.data.message)
          })
      }
    },

    onChangeFormStatus() {
      this.$emit('onChangeFormStatus')
    },
  },
}
</script>
