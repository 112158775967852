import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

// Dados que permanecem até ser atualizado
// Não estando no dataState a store é limpa após refresh
const dataState = createPersistedState({
  paths: [
    'project',
    'projectTickets',
    'user',
    'filterTicketlist',
    'filterUser',
    'filterProject',
    'filterSprints',
    'filterKanban',
    'filterDaily',
    'array_project',
    'filterProspeccao',
    'filterOpportunity',
    'filterFunctionality',
    'filterRight',
    'filterOpportunityDashboard',
    'filterTicket',
    'filterChart',
    'selectedChart',
    'filterDashboard',
    'array_user_projects_association',
  ],
})

export default createStore({
  state: {
    filterTicketlist: {},
    filterUser: {},
    filterKanban: {},
    filterGantt: {},
    filterSchedule: {},
    filterDaily: {},
    filterProject: {},
    filterStatus: {},
    filterSprints: {},
    filterSuporte: {},
    filterTicket: {},
    filterChart: {},
    sidebarVisible: '',
    selectedChart: '',
    sidebarUnfoldable: false,
    project: {},
    projectTickets: [],
    sprint: {},
    user: {},
    array_project: [],
    filterProspeccao: [],
    filterOpportunity: [],
    filterFunctionality: [],
    filterRight: [],
    filterOpportunityDashboard: [],
    filterFollowUpDayDashboard: [],
    filterProspectionDashboard: [],
    filterDashboard: {},
    array_user_projects_association: [],
  },
  plugins: [dataState],
  mutations: {
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
    updateSelectedChart(state, payload) {
      state.selectedChart = payload.value
    },
    setFilterUser(state, payload) {
      state.filterUser = payload.value
    },
    setFilterProject(state, payload) {
      state.filterProject = payload.value
    },
    setFilterStatus(state, payload) {
      state.filterStatus = payload.value
    },
    setFilterSprints(state, payload) {
      state.filterSprints = payload.value
    },
    setFilterKanban(state, payload) {
      state.filterKanban = payload.value
    },
    setFilterGantt(state, payload) {
      state.filterGantt = payload.value
    },
    setFilterSchedule(state, payload) {
      state.filterSchedule = payload.value
    },
    setFilterDaily(state, payload) {
      state.filterDaily = payload.value
    },
    setFilterSuporte(state, payload) {
      state.filterSuporte = payload.value
    },
    setFilterTicket(state, payload) {
      state.filterTicket = payload.value
    },
    setProject(state, payload) {
      state.project = payload.value
    },
    setProjectTickets(state, payload) {
      state.projectTickets = payload.value
    },
    setSprint(state, payload) {
      state.sprint = payload.value
    },
    setArrayProject(state, payload) {
      state.array_project = payload.value
    },
    setFilterProspeccao(state, payload) {
      state.filterProspeccao = payload.value
    },
    setFilterOpportunity(state, payload) {
      state.filterOpportunity = payload.value
    },
    setUser(state, payload) {
      state.user = payload.value
    },
    setFilterFunctionality(state, payload) {
      state.filterFunctionality = payload.value
    },
    setFilterRight(state, payload) {
      state.filterRight = payload.value
    },
    setFilterOpportunityDashboard(state, payload) {
      state.filterOpportunityDashboard = payload.value
    },
    setFilterFollowUpDayDashboard(state, payload) {
      state.filterFollowUpDayDashboard = payload.value
    },
    setFilterProspectionDashboard(state, payload) {
      state.filterProspectionDashboard = payload.value
    },
    setFilterChart(state, payload) {
      state.filterChart = payload.value
    },
    setFilterDashboard(state, payload) {
      state.filterDashboard = payload.value
    },
    setUserProjectsAssociation(state, payload) {
      state.array_user_projects_association = payload.value
    },
    setFilterTicketlist(state, payload) {
      state.filterTicketlist = payload.value
    },
  },
  actions: {},
  modules: {},
})
