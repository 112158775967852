<template>
  <b-modal
    centered
    :key="key"
    no-close-on-esc
    no-close-on-backdrop
    v-model="visible_reset_password"
    hide-footer
    hide-header
  >
    <Profile :key="key" @onChangeForm="onChangeProfile" :item="user"></Profile>
  </b-modal>
  <CDropdown variant="nav-item">
    <CDropdownToggle placement="bottom-end" :caret="false">
      {{ user ? user.login_user : '' }}

      <b-avatar variant="primary" size="30" class="ml-2">
        <BootstrapIcon icon="person" color="white" />
      </b-avatar>
    </CDropdownToggle>
    <CDropdownMenu>
      <CDropdownHeader class="bg-light fw-semibold py-2">
        Account
      </CDropdownHeader>
      <CDropdownItem>
        <CIcon icon="cil-bell" /> Atualizações
        <CBadge color="info" class="ms-auto">{{ itemsCount }}</CBadge>
      </CDropdownItem>
      <CDropdownItem> <CIcon icon="cil-user" /> Perfil </CDropdownItem>
      <CDropdownItem @click="onChangeProfile">
        <i class="pi pi-key"></i> Alterar Senha
      </CDropdownItem>
      <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
        Configurações
      </CDropdownHeader>
      <CDropdownItem>
        <CIcon icon="cil-settings" /> Configurações
      </CDropdownItem>
      <CDropdownItem style="cursor: pointer" @click="limparFiltros()">
        <BootstrapIcon icon="list-nested" color="back" />
        Limpar Filtros
      </CDropdownItem>
      <CDropdownItem @click="logout()">
        <CIcon icon="cil-lock-locked" /> Sair
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
import avatar from '@/assets/images/avatars/8.jpg'
import { useStore } from 'vuex'
import { computed } from 'vue'
import Profile from '../views/user/Profile.vue'
// import { getKeycloak } from '@baloise/vue-keycloak'
export default {
  name: 'AppHeaderDropdownAccnt',
  components: {
    Profile,
  },
  setup() {
    const store = useStore()
    return {
      user: computed(() => store.state.user),
      avatar: avatar,
      itemsCount: 42,
    }
  },
  data() {
    return {
      key: 0,
      visible_reset_password: false,
    }
  },
  methods: {
    onChangeProfile() {
      this.visible_reset_password
        ? (this.visible_reset_password = false)
        : (this.visible_reset_password = true)
    },
    logout() {
      localStorage.removeItem('token')
      this.$router.push({
        name: 'Login',
      })
    },
    limparFiltros() {
      this.$confirm.require({
        message: 'Deseja realmente limpar o cache?',
        header: 'Confirmação',
        acceptClass: 'p-button-success',
        acceptLabel: 'Sim',
        rejectLabel: 'Cancelar',
        reject: async () => {},
        accept: async () => {
          this.$store.commit({
            type: 'setFilterUser',
            value: {},
          })
          this.$store.commit({
            type: 'setFilterProject',
            value: {},
          })
          this.$store.commit({
            type: 'setFilterSprints',
            value: {},
          })
          this.$store.commit({
            type: 'setFilterKanban',
            value: {},
          })
          this.$store.commit({
            type: 'setFilterDaily',
            value: {},
          })
          // this.$store.commit({
          //   type: 'setArrayProject',
          //   value: {},
          // })
          this.$store.commit({
            type: 'setFilterProspeccao',
            value: {},
          })
          this.$store.commit({
            type: 'setFilterOpportunity',
            value: {},
          })
          this.$store.commit({
            type: 'setFilterFunctionality',
            value: {},
          })
          this.$store.commit({
            type: 'setFilterRight',
            value: {},
          })
          this.$store.commit({
            type: 'setFilterOpportunityDashboard',
            value: {},
          })
          this.$store.commit({
            type: 'setFilterTicket',
            value: {},
          })
          this.$store.commit({
            type: 'setFilterChart',
            value: {},
          })
          this.$store.commit({
            type: 'updateSelectedChart',
            value: {},
          })
        },
      })
    },
  },
}
</script>
