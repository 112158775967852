<template>
  <b-card class="mt-2">
    <template #header>
      {{ model.id == null ? 'Cadastro' : 'Alteração: ' + model.id }}
      <b-badge variant="success" class="mr-2">Projeto</b-badge>
    </template>
    <div>
      <b-form @submit="onSubmit(model)" class="row">
        <b-form-group label="Horas Projeto" class="col-lg-4">
          <b-form-input
            type="number"
            v-model="model.hours_project"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Orçamento" class="col-lg-4">
          <b-form-input v-model="model.budget"></b-form-input>
        </b-form-group>
        <b-form-group label="Status" class="col-lg-2">
          <b-form-checkbox
            switch
            v-model="model.status"
            required
            color="success"
            value="1"
            unchecked-value="0"
          >
          </b-form-checkbox>
        </b-form-group>
        <b-form-group label="Titulo" class="col-lg-12">
          <b-form-input v-model="model.description" required></b-form-input>
        </b-form-group>
        <b-form-group label="Empresa" class="col-lg-6">
          <b-form-select
            class="form-control"
            v-model="model.company_id"
            :options="array_company"
            value-field="id"
            text-field="name"
            required
          ></b-form-select>
        </b-form-group>
        <b-form-group label="Tipo" class="col-lg-6">
          <b-form-select
            v-model="model.status_type_id"
            :options="array_status_type"
            value-field="id"
            text-field="description"
            required
          ></b-form-select>
        </b-form-group>

        <b-form-group label="Observação" class="col-lg-12">
          <b-form-textarea v-model="model.obs"></b-form-textarea>
        </b-form-group>
        <b-form-group label="Nota" class="col-lg-12">
          <b-form-textarea v-model="model.note"></b-form-textarea>
        </b-form-group>
        <b-form-group label="Data Inicio" class="col-lg-6">
          <DatePickerNow
            model="start_date"
            :key="key"
            :date="model.start_date"
            @onChangeDatePicker="onChangeDatePicker"
          />
        </b-form-group>
        <b-form-group label="Data Fim" class="col-lg-6">
          <DatePickerNow
            model="end_date"
            :key="key"
            :date="model.end_date"
            @onChangeDatePicker="onChangeDatePicker"
          />
        </b-form-group>
        <b-row class="text-end">
          <b-col class="mb-3">
            <b-button class="ml-2" type="submit" variant="primary">
              Salvar
            </b-button>
            <b-button
              class="ml-2"
              variant="light"
              @click="onChangeFormProject()"
              >X</b-button
            >
          </b-col>
        </b-row>
      </b-form>
    </div>
  </b-card>
</template>
<script>
import service from '@/services/BaseService'
import DatePickerNow from '@/components/DatePickerNow.vue'

import moment from 'moment'
export default {
  components: {
    DatePickerNow,
  },
  props: {
    item: {
      id: String,
      description: String,
      hours_project: String,
      budget: String,
      start_date: String,
      end_date: String,
      note: String,
      status: String,
      company_id: String,
      obs: String,
      status_type_id: String,
      created_at: String,
    },
  },
  data() {
    return {
      key: 0,
      model: this.item,
      array_company: [],
      array_status_type: [],
    }
  },
  created() {
    this.getCompany()
    this.getStatusType()
  },
  methods: {
    async onChangeDatePicker(value) {
      if (value != null) {
        this.model[value.model] = value.value
      } else {
        this.model[value.model] = null
      }
    },

    async getCompany() {
      var http = new service(`person`)
      await http
        .find()
        .then((resp) => {
          this.array_company = resp.data.data
        })
        .catch(() => {})
    },

    async getStatusType() {
      var http = new service(`status_type`)
      await http
        .find()
        .then((resp) => {
          this.array_status_type = resp.data.data
        })
        .catch(() => {})
    },

    onChangeFormProject() {
      this.$emit('onChangeFormProject')
    },

    async onSubmit(item) {
      var body = {
        id: item.id,
        description: item.description,
        hours_project: item.hours_project,
        budget: item.budget,
        start_date:
          item.start_date != null
            ? moment(item.start_date, 'DD-MM-YYYY').format('YYYY-MM-DD')
            : null,
        end_date:
          item.end_date != null
            ? moment(item.end_date, 'DD-MM-YYYY').format('YYYY-MM-DD')
            : null,
        note: item.note,
        status: item.status,
        company_id: item.company_id,
        obs: item.company_id,
        status_type_id: item.status_type_id,
      }
      var http = new service(`project`)
      if (body.id != null) {
        await http
          .update(body.id, body)
          .then(() => {
            this.updateTicket = false
            this.$toast.success('Projeto Atualizado com Sucesso!')
            this.$emit('onChangeFormProject')
          })
          .catch((e) => {
            this.$toast.error(e.response.data.message)
          })
      } else {
        await http
          .create(body)
          .then((resp) => {
            this.updateTicket = false
            this.$toast.success('Projeto Criado com Sucesso!')
            item = resp.data.data
            this.model = resp.data.data
            this.$emit('onChangeFormProject')
          })
          .catch((e) => {
            this.$toast.error(e.response.data.message)
          })
      }
    },
  },
}
</script>
