<template>
  <b-card class="mt-2">
    <template #header>
      {{ form.id == null ? 'Cadastro' : 'Alteração: ' + form.id }}
      <b-badge variant="success" class="mr-2">Usuário</b-badge>
    </template>
    <div>
      <b-form @submit="onSubmit" class="row">
        <b-form-group label="Ativo" class="col-lg-12">
          <InputSwitch v-model="form.active" trueValue="S" falseValue="N" />
        </b-form-group>
        <b-form-group label="Email" class="col-lg-4">
          <b-form-input v-model="form.login_email" required></b-form-input>
        </b-form-group>
        <b-form-group label="Login" class="col-lg-4">
          <b-form-input v-model="form.login_user" required></b-form-input>
        </b-form-group>
        <b-form-group
          label="Senha"
          class="col-lg-4"
          v-if="this.form.id == null"
        >
          <b-form-input v-model="form.password" required></b-form-input>
        </b-form-group>
        <b-row class="text-end">
          <b-col class="mb-3">
            <b-button class="ml-2" type="submit" variant="primary">
              Salvar
            </b-button>
            <b-button class="ml-2" variant="light" @click="onChangeForm()"
              >X</b-button
            >
          </b-col>
        </b-row>
      </b-form>
    </div>
  </b-card>
</template>

<script>
import service from '@/services/BaseService'
export default {
  props: {
    item: Object,
  },
  data() {
    return {
      form: this.item ? this.item : { active: true },
    }
  },
  methods: {
    async onSubmit() {
      this.form.person_id = 5
      this.form.company_login_id = 3

      var http = new service(`user`)
      if (this.form.id == null) {
        await http
          .create(this.form)
          .then((resp) => {
            this.$toast.success('Usuário Criado com Sucesso!')
            this.$emit('onChangeForm', resp.data.data)
          })
          .catch((e) => {
            this.$toast.error(e.response.data.message)
          })
      } else {
        await http
          .update(this.form.id, this.form)
          .then(() => {
            this.$toast.success('Usuário Atualizado com Sucesso!')
            this.$emit('onChangeForm')
          })
          .catch((e) => {
            this.$toast.error(e.response.data.message)
          })
      }
    },
    onChangeForm() {
      this.$emit('onChangeForm')
    },
  },
}
</script>
