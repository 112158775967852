import axios from 'axios'
//import router from '../router'
/** @type {BaseService} */
export default class BaseService {
  constructor(api) {
    this.api = `${process.env.VUE_APP_ROOT_API}${api}`
    //this.api = `http://localhost:5000/api/${api}`
    axios.defaults.headers.common['x-api-key'] = `${localStorage.token}`
    axios.defaults.headers.common['ambiente'] = 'Portal'
    this.axios = axios
  }

  /**
   * Listar todos os itens
   */
  find(obj) {
    localStorage.setItem('session', new Date().getTime())
    //
    if (obj) {
      const query = Object.keys(obj)
        .map((k) => `${k}=${encodeURIComponent(obj[k])}`)
        .join('&')
      return this.axios.get(`${this.api}?${query}`)
    } else {
      return this.axios.get(`${this.api}`)
    }
  }

  /**
   * Custom Post
   */
  post(url, obj) {
    localStorage.setItem('session', new Date().getTime())
    if (url != undefined) {
      return this.axios.post(`${this.api}/${url}`, obj)
    }
    return this.axios.post(`${this.api}`, obj)
  }

  /**
   * Listar todos os itens
   */
  params(obj) {
    const query = Object.keys(obj)
      .map(() => '${k}=${encodeURIComponent(obj[k])}')
      .join('&')
    return this.axios.get(`${this.api}/param?${query}`)
  }

  /**
   * Listar todos os itens
   */
  search(obj) {
    localStorage.setItem('session', new Date().getTime())
    const query = Object.keys(obj)
      .map(() => '${k}=${encodeURIComponent(obj[k])}')
      .join('&')
    return this.axios.get(`${this.api}/search?${query}`)
  }

  /**
   *  Selecionar o equivalente ao id passado
   * @param {*} id
   */
  findOne(id) {
    localStorage.setItem('session', new Date().getTime())
    return this.axios.get(`${this.api}/${id}`)
  }

  /**
   *  Inserir um novo item
   * @param {*} data
   */
  create(obj) {
    localStorage.setItem('session', new Date().getTime())
    return this.axios.post(`${this.api}`, obj)
  }

  /**
   *  Atualizar um item
   * @param {*} data
   */
  update(id, data) {
    localStorage.setItem('session', new Date().getTime())
    return this.axios.put(`${this.api}/${id}`, data)
  }
  /**
   *  Atualizar um item por um endpoint distinto, sem a necessidade de passar um id.
   * @param {*} data
   */
  forceUpdate(data) {
    localStorage.setItem('session', new Date().getTime())
    return this.axios.put(`${this.api}`, data)
  }

  /**
   * Remover um item
   * @param {*} id
   */
  delete(id) {
    localStorage.setItem('session', new Date().getTime())
    return this.axios.delete(`${this.api}/${id}`)
  }
  /**
   *  Inserir um novo item type form-data
   * @param {*} data
   */
  createFormData(data) {
    localStorage.setItem('session', new Date().getTime())
    const config = {
      headers: { 'content-type': 'multipart/form-data' },
    }

    return this.axios.post(`${this.api}`, data, config)
  }
}
