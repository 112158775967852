export default [
  {
    component: 'CNavItem',
    name: 'Dashboard',
    to: '/dashboard',
    icon: 'cil-speedometer',
    badge: {
      color: 'primary',
      text: 'Principal',
    },
  },
  {
    component: 'CNavItem',
    name: 'Projetos',
    to: '/projetos',
    icon: 'cil-list',
  },
  {
    component: 'CNavItem',
    name: 'Kanban',
    to: '/kanban',
    icon: 'cil-list',
  },
  {
    component: 'CNavItem',
    name: 'Ticket List',
    to: '/tickets-list',
    icon: 'cil-list',
  },
  {
    component: 'CNavItem',
    name: 'Graficos',
    to: '/charts',
    icon: 'cil-chart-line',
  },
  {
    component: 'CNavItem',
    name: 'Dev',
    to: '/list-tickets',
    icon: 'cil-task',
  },
  {
    component: 'CNavItem',
    name: 'Suporte',
    to: '/list-projects-suport',
    icon: 'cil-task',
  },
  {
    component: 'CNavItem',
    name: 'Suporte Atendimento',
    to: '/suporte',
    icon: 'cil-task',
  },
  {
    component: 'CNavGroup',
    name: 'Resumos',
    icon: 'cil-list',
    items: [
      {
        component: 'CNavItem',
        name: 'Relatório de Horas',
        to: '/hours_report',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Cadastros Gerais',
    icon: 'cil-list',
    items: [
      {
        component: 'CNavItem',
        name: 'Status',
        to: '/status',
      },
      {
        component: 'CNavItem',
        name: 'Sprint',
        to: '/sprint',
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'CRM',
    icon: 'cil-list',
    items: [
      {
        component: 'CNavItem',
        name: 'Agenda',
        to: '/schedule_follow_up',
      },
      {
        component: 'CNavItem',
        name: 'Acompanhamento',
        to: '/follow_up',
      },
      {
        component: 'CNavItem',
        name: 'Oportunidade',
        to: '/opportunity',
      },
      {
        component: 'CNavItem',
        name: 'Prospecção',
        to: '/prospeccao',
      },
      {
        component: 'CNavItem',
        name: 'Contato',
        to: '/contato',
      },
      {
        component: 'CNavGroup',
        name: 'Cadastros',
        icon: 'cil-list',
        items: [
          {
            component: 'CNavItem',
            name: 'Classe',
            to: '/classe',
          },
          {
            component: 'CNavItem',
            name: 'Contato Setor',
            to: '/contato_setor',
          },
          {
            component: 'CNavItem',
            name: 'Oportunidade Status',
            to: '/opportunity_status',
          },
          {
            component: 'CNavItem',
            name: 'Tipo Acompanhamento',
            to: '/type_follow_up',
          },
        ],
      },
    ],
  },
  {
    component: 'CNavGroup',
    name: 'Utils',
    icon: 'cil-list',
    items: [
      {
        component: 'CNavItem',
        name: 'Usuário',
        to: '/user',
      },
      {
        component: 'CNavItem',
        name: 'Funcionalidade',
        to: '/functionality',
      },
      {
        component: 'CNavItem',
        name: 'Perfil',
        to: '/profile',
      },
      {
        component: 'CNavItem',
        name: 'Direitos',
        to: '/right',
      },
    ],
  },
]
