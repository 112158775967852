<template>
  <b-form-group label="Empresa">
    <b-form-select
      class="form-control"
      v-model="mutableProp.company_id"
      :key="key"
      @change="onChangeCombo"
    >
      <b-form-select-option
        v-for="obj in itemsArray"
        :key="obj"
        :value="obj.id"
        >{{ obj.name }}</b-form-select-option
      >
    </b-form-select>
  </b-form-group>
</template>

<script>
import service from '@/services/BaseService'
export default {
  props: ['form'],
  data() {
    return {
      key: 0,
      mutableProp: this.form,
      itemsArray: [],
    }
  },
  created() {
    this.getProjetos()
  },
  watch: {
    form(value) {
      this.mutableProp.company_id = value.company_id
      this.key++
    },
  },
  methods: {
    onChangeCombo(value) {
      this.$emit('onChangeCombo', value)
    },
    async getProjetos() {
      var http = new service(`person`)
      await http
        .find()
        .then((resp) => {
          this.itemsArray = resp.data.data
        })
        .catch(() => {})
    },
  },
}
</script>
